@font-face {
  font-family: 'Radikal';
  src: url('/assets/fonts/radikal/Radikal-Black.woff2') format('woff2'),
    url('/assets/fonts/radikal/Radikal-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Radikal';
  src: url('/assets/fonts/radikal/Radikal-BlackItalic.woff2') format('woff2'),
    url('/assets/fonts/radikal/Radikal-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Radikal';
  src: url('/assets/fonts/radikal/Radikal.woff2') format('woff2'),
    url('/assets/fonts/radikal/Radikal.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Radikal';
  src: url('/assets/fonts/radikal/Radikal-Italic.woff2') format('woff2'),
    url('/assets/fonts/radikal/Radikal-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Radikal';
  src: url('/assets/fonts/radikal/Radikal-Light.woff2') format('woff2'),
    url('/assets/fonts/radikal/Radikal-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Radikal';
  src: url('/assets/fonts/radikal/Radikal-BoldItalic.woff2') format('woff2'),
    url('/assets/fonts/radikal/Radikal-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Radikal';
  src: url('/assets/fonts/radikal/Radikal-LightItalic.woff2') format('woff2'),
    url('/assets/fonts/radikal/Radikal-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Radikal';
  src: url('/assets/fonts/radikal/Radikal-Bold.woff2') format('woff2'),
    url('/assets/fonts/radikal/Radikal-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Radikal';
  src: url('/assets/fonts/radikal/Radikal-Medium.woff2') format('woff2'),
    url('/assets/fonts/radikal/Radikal-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Radikal UltraThin';
  src: url('/assets/fonts/radikal/Radikal-UltraThin.woff2') format('woff2'),
    url('/assets/fonts/radikal/Radikal-UltraThin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Radikal UltraThin';
  src: url('/assets/fonts/radikal/Radikal-UltraThinItalic.woff2') format('woff2'),
    url('/assets/fonts/radikal/Radikal-UltraThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Radikal';
  src: url('/assets/fonts/radikal/Radikal-MediumItalic.woff2') format('woff2'),
    url('/assets/fonts/radikal/Radikal-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Radikal';
  src: url('/assets/fonts/radikal/Radikal-Thin.woff2') format('woff2'),
    url('/assets/fonts/radikal/Radikal-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Radikal';
  src: url('/assets/fonts/radikal/Radikal-ThinItalic.woff2') format('woff2'),
    url('/assets/fonts/radikal/Radikal-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
